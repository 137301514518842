import { defineStore } from "pinia";

export const useProductStore = defineStore("productStore", {

  state: () => ({

    http: useNuxtApp().$http,

    loading: false,

    query: {
      search: null,
      categoryIds: null,
      page: null,
      low_stock: false,
      displayOnSite: null,
      statuses: [1]
    },

    products: {
      data: [
      ]
    },

    selectedProduct: [],

  }),

  actions: {

    async setShowSelect(value) {
      this.showSelect = value
    },

    async getProducts() {

      this.loading = true

      const data = await this.http.index("product/product", this.query);

      this.loading = false

      this.products = data.products
    },

    async getProduct(id) {

      const data = await this.http.show("product/product", id);

      return data.product

    },

    async updateStatus(id, status) {

      await this.http.store("product/update-product", { selectedProductIds: [id], status: status });

    },

    async updateDisplayOnSite(id, displayOnSite) {

      await this.http.store("product/update-product", { selectedProductIds: [id], display_on_site: displayOnSite });

    },

    async updateIsFeatured(id, isFeatured) {

      await this.http.store("product/update-product", { selectedProductIds: [id], is_featured: isFeatured });

    },

    async syncWithCompanyGroup(id) {

      await this.http.store("product/sync-with-company-group", { product_id: id });
    }

  },
});
